<template>
  <section class="brandManage">
    <h2 class="title-page">品牌管理</h2>
    <div class="content-brand">
      <div class="brand-flex brand-info">
        <el-input placeholder="请输入品牌名"  v-model="name" class="name-input" >
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
        <el-button type="primary" icon="el-icon-plus" @click="addDialog=true">新增</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%" :header-cell-style="{'background-color':'rgba(175, 192, 207, 0.2)'}">
        <el-table-column prop="id" label="品牌ID" width="180"></el-table-column>
        <el-table-column prop="name" label="品牌名"></el-table-column>
        <el-table-column prop="qw" label="企微公司ID"></el-table-column>
        <el-table-column prop="my" label="企微密钥"></el-table-column>
        <el-table-column prop="dy" label="道一ID"></el-table-column>
        <el-table-column prop="add" label="新增时间"></el-table-column>
        <el-table-column prop="edit" label="编辑时间"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text">编辑</el-button>
            <el-button type="text" @click="showCompany(scope.row)">门店管理</el-button>
            <el-button type="text">停用</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 新增品牌 -->
    <el-dialog title="新增品牌" :visible.sync="addDialog" width="680px" >
      <el-form ref="form" :rules="rules" :model="form" label-width="110px">
        <el-form-item label="品牌名" prop="name">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="企微ID" prop="name">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="企微密钥" prop="name">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="企微应用密钥" prop="name">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="道一开发者ID">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="道一凭证密钥">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="addDialog=false" class="close-btn">取 消</el-button>
        <el-button type="primary" @click="addInfo">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 门店管理 -->
    <el-dialog  :visible.sync="companyDialog" width="680px" :show-close="false">
      <div class="brand-flex company-title">
        <span slot="title" class="titles">门店管理</span>
        <el-button type="primary" @click="addCompany=true">新增</el-button>
      </div>
      <el-table :data="companyList" style="width: 100%" :header-cell-style="{'background-color':'rgba(175, 192, 207, 0.2)'}">
        <el-table-column prop="name" label="企微门店"></el-table-column>
        <el-table-column prop="bm" label="门店别名"></el-table-column>
        <el-table-column prop="company_id" label="财升门店ID"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="toEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="toDel(scope.row.company_id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 编辑门店 -->
      <el-dialog width="680px" title="编辑门店" :visible.sync="editCompany" append-to-body>
        <el-form :model="companyInfo" label-width="110px">
          <el-form-item label="企微门店" prop="name">
            <span>天府五街店</span>
          </el-form-item>
          <el-form-item label="门店别名" prop="name">
            <el-input v-model="companyInfo.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="财升门店ID" prop="name">
            <el-input v-model="companyInfo.company_id" clearable></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="editCompany=false" class="close-btn">取 消</el-button>
          <el-button type="primary" @click="editQeury">确 定</el-button>
        </div>
      </el-dialog>
    </el-dialog>
  </section>
</template>
<script>
  export default {
    name: 'brandManage',
    data(){
      return {
        name: '',
        tableData: [
          {
            id: 1,
            name: '音乐派KTV',
            qw: 123,
            my: '324234223',
            dy: 1,
            add: '2021-09-01 10:00',
            edit: '2021-09-01 10:00'
          }
        ],
        addDialog: false,
        form: {
          name: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入品牌名称', trigger: 'blur' }
          ]
        },
        companyDialog: false,
        companyList: [
          {
            company_id: '123',
            name: '总部',
            bm: '音乐派KTV'
          }
        ],
        editCompany: false, //编辑门店
        companyInfo: {},
        addCompany: false,
      }
    },
    methods: {
      //新增品牌
      addInfo: function(){

      },
      //门店管理
      showCompany: function(){
        this.companyDialog = true;
      },
      //编辑门店
      toEdit: function(val){
        this.editCompany = true;
        this.companyInfo = val;
      },
      //编辑确认
      editQeury: function(){
        this.editCompany = false;
        this.$message('编辑确认')
      },
      //删除门店
      toDel: function(id){
        console.log(id)
        this.$alert('是否确认删除该门店', '确认删除', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'info',
              message: `删除成功${action}`
            });
          }
        });
      }
    }
  }
</script>
<style scoped>
  .brandManage {
    min-height: 100%;
    background-color: #f7fafb;
  }
  .title-page{
    font-size: 30px;
    color: #3e566e;
    padding-top: 40px;
    padding-bottom: 30px;
  }
  .content-brand{
    padding: 0 40px;
    background-color: #ffffff;
    box-shadow: 0px 10px 20px 0px rgba(62, 86, 110, 0.06);
    border-radius: 6px;
  }
  .brand-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .name-input{
    width: 380px;
  }
  .brand-info{
    padding: 40px 0;
  }
  .brand-info /deep/ .el-input-group__append{
    border-left: 1px solid #ffffff;
    background-color: #ffffff;
    margin-left: -1px;
  }
  .el-form /deep/ .el-form-item__label{
    text-align: left;
  }
  .close-btn{
    background-color: rgba(62, 86, 110, 0.1);
    border-color: rgba(62, 86, 110, 0.1);
  }
  .brandManage /deep/ .el-dialog__footer{
    text-align: center;
  }
  .brandManage /deep/ .company-title{
    margin-top: -10px;
    margin-bottom: 20px;
  }
  .titles{
    font-size: 20px;
    color: #3E566E;
    font-weight: 500;
    font-family: PingFangSC-Medium, PingFang SC;
  }
</style>